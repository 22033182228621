import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import swal from "sweetalert2"
import Loader from "react-loader-spinner"
import Layout from '../components/layout'
import pic01 from '../assets/images/pic01.jpg'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic04 from '../assets/images/pic04.jpg'
import map from '../assets/images/map.png'
// import LifeImage from '../assets/images/home/life-groups2.jpg'
import LifeImage from '../assets/images/groups/Homegroups.jpg'

import Header from "../components/Header"
import { sendLifeGroup } from "../lib/API"



const MediaPage = () => {
    const siteTitle = "Blackheath and Charlton Baptist Church - Life Groups"
    const headerImages = [ LifeImage ]
    const headerTitle = 'Life Groups'
    const [ name, setName ] = useState( "" )
    const [ email, setEmail ] = useState( "" )
    const [ address, setAddress ] = useState( "" )
    const [ postcode, setPostCode ] = useState( "" )
    const [ loading, setLoading ] = useState( false )
    const postcodeRegex = "^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$"

    const changeValue = ( e, func ) => func( e.target.value )

    const send = async e => {
        e.preventDefault()
        e.stopPropagation()
        setLoading( true )
        const data = { name, email, address, postcode }
        const res = await sendLifeGroup( data )
        setLoading( false )
        
        if( res.sent ) {
            swal.fire( 'Success', 'Message sent successfully', 'success' ).then( () => {
                setName( '' )
                setEmail( '' )
                setAddress( '' )
                setPostCode( '' )
            } )    
        } else {
            swal.fire( 'Could not send message', 'Please try again later', 'error' )
        }
    }

    return (
        
        <div id='lifegroup-page'>
            <Helmet title={siteTitle} />
            <Header images={headerImages} headerTitle={headerTitle} tint={0.3} />    

            <section id="lifegroup-form" className="main style1 special">
                <div className="grid-wrapper">
                    <div className="col-12">
                        <header className="major">
                            <h2>Find a Group</h2>
                        </header>
                    </div>

                    <div className='col-12 lifegroup-input'>
                        <form onSubmit={send}>
                            <div className="grid-wrapper">

                                <div className='col-6'>
                                    <input className="form-input" required type="text" name="name" placeholder="Full Name*" value={name} onChange={e => changeValue( e, setName )} /><br/>
                                </div>
                                
                                <div className='col-6'>
                                    <input className="form-input" type="email" required name="email" placeholder="Email*" value={email} onChange={e => changeValue( e, setEmail )}/><br/>
                                </div>
                                
                                <div className='col-8'>
                                    <input className="form-input" type="text" required name="address1" placeholder="Address*" value={address} onChange={e => changeValue( e, setAddress )}/><br/>
                                </div>

                                <div className='col-4'>
                                    <input className="form-input" type="text" pattern={postcodeRegex} required name="postcode" placeholder="Post Code*" value={postcode} onChange={e => changeValue( e, setPostCode )}/><br/>
                                </div>
                                <div className='col-12'>
                                    <br />
                                    {
                                        !loading ? 
                                            <input type="submit" value="Submit" className="button special" /> 
                                        :
                                            <Loader
                                                type="Puff"
                                                color="#00BFFF"
                                                height={40}
                                                width={50}
                                            />
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default MediaPage